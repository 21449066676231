/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable class-methods-use-this */
import { PureComponent } from 'react';
import { Grid, Button } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import { ROLE_MAPPING } from 'dataModule/constants/profile';
import { ImageAvatar } from 'utilsModule/components';
import { withStyles } from '@material-ui/styles';
import { ONLINE } from '../ducks';
// Icons
import ExitToApp from '@material-ui/icons/ExitToApp';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';

@withStyles(() => ({
  root: {
    '&:hover': {
      backgroundColor: '#DDDDDD',
    },
  },
  activedIcon: {
    color: '#5db057',
    width: '14px',
    height: '14px',
    margin: '0px',
  },
  inactivedIcon: {
    color: '#9E9E9E',
    width: '14px',
    height: '14px',
    margin: '0px',
  },
  btn: {
    borderRadius: 50,
    width: '64px',
    height: '30px',
    background: '#212121 0% 0% no-repeat padding-box',
    color: '#FFFFFF',
  },
  ExpansionPanelStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    expanded: {},
  },
  ExpansionPanelDetailsStyle: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
  },
  ExpansionPanelSummaryStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
  panel: {
    display: 'absolute',
    top: '95px',
    rigit: '10px',
  },
}))
class Panel extends PureComponent {
  state = {
    disabledMenu: false,
  };

  handleSwitchRole = (id) => {
    this.setState({ disabledMenu: !this.state.disabledMenu }, async () => {
      const result = await this.props.handleSwitchRole(id);
      if (result.error) {
        this.setState({ disabledMenu: false });
      }
    });
  };

  render() {
    const {
      currentUser: {
        organization: { name },
        profile: {
          name: { firstName, lastName },
          type,
        },
        profiles,
      },
      style,
      profilePhoto,
      defaultUrl,
      online,
      offline,
      onSignOut,
      status,
      classes,
      edit,
      currentUser,
    } = this.props;
    const { disabledMenu } = this.state;
    const roleCheck = [
      { id: 'SUPERADMIN', label: 'Super Admin' },
      { id: 'ADMIN', label: 'Admin' },
    ].concat(ROLE_MAPPING);

    const role = roleCheck.find((r) => r.id === type);

    const filteredProfile = Array.isArray(profiles) ? profiles.filter((e) => e.id !== currentUser.profile.id) : [];

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center" style={{ padding: '16px 16px 16px 16px' }}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item>
                  <ImageAvatar className={style} src={profilePhoto} defaultUrl={defaultUrl} />
                </Grid>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography noWrap variant="body1" gutterBottom={!role}>
                        {firstName} {lastName}
                      </Typography>
                      {role && (
                        <Typography display="block" variant="caption">
                          {role.label}
                        </Typography>
                      )}
                      <Typography display="block" noWrap variant="caption">
                        {name}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button variant="contained" className="milo-btn-black" onClick={edit}>
                Edit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {
          filteredProfile.length > 0 &&
          <Grid item xs={12}>
            <ExpansionPanel className={classes.ExpansionPanelStyle}>
              <ExpansionPanelSummary style={{ padding: '0px 16px 0px 0px' }} expandIcon={<ExpandMoreIcon />}>
                <Grid container alignItems="center" style={{ padding: '0px 0px 0px 16px' }}>
                  <Typography variant="body2">Switch Account</Typography>
                </Grid>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.ExpansionPanelDetailsStyle} style={{ padding: '0px' }}>
                <MenuList role="menu" style={{ width: '100%' }}>
                  {filteredProfile.map((e, i) => {
                    const {
                      id,
                      organization: { name },
                      role,
                    } = e;
                    const formattedRole = role === 'SUPERADMIN' ? 'Super Admin' : _.capitalize(_.replace(role, '_', ' '));
                    return (
                      <MenuItem
                        key={i}
                        className={classes.root}
                        style={{
                          padding: '0px 0px 0px 16px',
                          minHeight: '0px',
                          minWidth: '0px',
                        }}
                        onClick={() => !disabledMenu && this.handleSwitchRole(id)}
                      >
                        <Typography variant="body2">
                          {name} - {formattedRole}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </Grid>
        }
        <Grid item xs={12}>
          <ExpansionPanel className={classes.ExpansionPanelStyle}>
            <ExpansionPanelSummary
              className={classes.ExpansionPanelSummaryStyle}
              style={{ padding: '0px 16px 0px 0px' }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Grid container alignItems="center" style={{ padding: '0px 0px 0px 16px' }}>
                <Grid item>
                  <Typography variant="body2">Chat Status:</Typography>
                </Grid>
                <Grid item style={{ margin: '2px 4px 0px 4px' }}>
                  <FiberManualRecord
                    style={{
                      color: status === ONLINE ? '#5db057' : '#9E9E9E',
                      width: '14px',
                      height: '14px',
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2">{status === ONLINE ? 'Online' : 'Offline'}</Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.ExpansionPanelDetailsStyle} style={{ padding: '0px' }}>
              <MenuList role="menu" style={{ width: '100%' }}>
                <MenuItem
                  className={classes.root}
                  style={{
                    padding: '0px 0px 0px 16px',
                    minHeight: '0px',
                    minWidth: '0px',
                  }}
                  onClick={online}
                >
                  <FiberManualRecord
                    style={{
                      color: '#5db057',
                      width: '14px',
                      height: '14px',
                      marginRight: '4px',
                    }}
                  />
                  <Typography variant="body2">Online</Typography>
                </MenuItem>
                <MenuItem
                  className={classes.root}
                  style={{
                    padding: '0px 0px 0px 16px',
                    minHeight: '0px',
                    minWidth: '0px',
                  }}
                  onClick={offline}
                >
                  <FiberManualRecord
                    style={{
                      color: '#9E9E9E',
                      width: '14px',
                      height: '14px',
                      marginRight: '4px',
                    }}
                  />
                  <Typography variant="body2">Offline</Typography>
                </MenuItem>
              </MenuList>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
        <Grid item xs={12}>
          <MenuItem
            className={classes.root}
            style={{
              padding: '0px 0px 0px 16px',
              minHeight: '0px',
              minWidth: '0px',
            }}
            onClick={onSignOut}
          >
            <ExitToApp style={{ marginRight: '8px' }} />
            <Typography variant="body2">Log Out</Typography>
          </MenuItem>
        </Grid>
      </Grid>
    );
  }
}

export default Panel;
